import React from "react"
import Layout from "../../components/layouts/Layout"
import { graphql, Link } from "gatsby"
import SEO from "../../components/Seo"

const CategoryPage = ({ data }) => {
  return (
    <>
      <Layout>
        <SEO
          title={data.sanityCategory.title}
          description={data.sanityCategory.description}
        />
        <article className="prose prose-lg mx-auto">
          <h1>{data.sanityCategory.title}</h1>
        </article>
      </Layout>
    </>
  )
}

export default CategoryPage

export const query = graphql`
  query ($id: String!) {
    sanityCategory(id: { eq: $id }) {
      title
      description
      slug {
        current
      }
    }
  }
`
